import React from 'react'
import styled, { css } from 'styled-components'
import { SharedStyles } from "../../../kenra-storybook/index"
import settings from "../../../kenra-storybook/global/settings"
const { StPageTitle, StLinkMore } = SharedStyles


const GroupContainer = styled.div`
  display: flex;
  text-align: center;
  flex-direction: column;
  max-width: 100%;
  margin: auto;
  @media (min-width: ${settings.bp.medium.view}) {
    max-width: 90%;
  }
  @media (min-width: ${settings.bp.large.view}) {
    max-width: 80%;
  }
`


const StGroupTitleText = styled.div`
font-size: 18px;
font-weight: 400;
line-height: 24px;
letter-spacing: 2px;
text-align: center;
margin-bottom: 48px;
margin-left: 20%;
margin-right: 20%;
color: #121212B3;
@media (max-width: ${settings.bp.small.view}) {
  margin-left: 5%;
  margin-right: 5%;
}
`

const StGroupRow = styled.div`
  display: flex;
  text-align: center;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`

const StGroupRowItem = styled.div`
  display: flex;
  text-align: center;
  flex-direction: column;
  max-width: 28%;
  margin-left: 2%;
  margin-right: 2%;
  margin-bottom: 60px;
  @media (max-width: ${settings.bp.medium.view}) {
    max-width: 40%;
    margin-left: 5%;
    margin-right: 5%;
}
  @media (max-width: ${settings.bp.small.view}) {
    max-width: 100%;
    margin-left: 0%;
    margin-right: 0%;
}
`

const StPersonName = styled.div`
font-size: 16px;
font-weight: 600;
line-height: 18.75px;
letter-spacing: 1.71px;
text-align: center;
color: #121212;
margin-top: 10px;
`

const StPersonHandle = styled.div`
font-size: 13px;
font-weight: 400;
line-height: 17.14px;
letter-spacing: 1.71px;
text-align: center;
color: #121212B3;
text-transform: uppercase;
`

const TeamMember = props => {
  const { image, name, handle } = props
  return (
    <StGroupRowItem>
      <img src={image} />
      <StPersonName>{name}</StPersonName>
      <StPersonHandle>{handle}</StPersonHandle>
    </StGroupRowItem>
  )
}

export const TeamGroup = props => {
  const { title, text, people } = props
  return (
    <GroupContainer>
      <StPageTitle
        style={{
          fontSize: "28px",
          lineHeight: "32px",
          letterSpacing: "2px",
          color: "#121212",
          fontWeight: '600',
          textTransform: 'uppercase',
          marginBottom: '0px',
          paddingLeft: "10px",
          paddingRight: "10px"
        }}>
        {title}
      </StPageTitle>
      <div style={{ width: "172px", height: "1px", backgroundColor: "#000000", margin: 'auto', marginTop: '24px', marginBottom: '24px' }} />
      <StGroupTitleText>{text}</StGroupTitleText>
      <StGroupRow>
        {people.map(p => {
          return (
            <TeamMember
              key={p.slideLabel}
              image={p.image?.localFile?.publicURL}
              name={p.slideLabel}
              handle={p.slideText}
            />
          )
        })}
      </StGroupRow>
    </GroupContainer>
  )
}
