import React from 'react'
import { injectIntl } from "gatsby-plugin-react-intl"
import { Spacing, InSalonRequestBanner, } from '../../../kenra-storybook'
import { TeamGroup } from './TeamGroup'
import { Banner } from './Banner'

function parseSection3Fragments(fragments, intl) {
  let fragmentDataMap = fragments?.map(section => {
    let returnData = {}
    section.forEach(fragmentData => {
      switch (fragmentData.key) {
        case 'Title':
          returnData['title'] = fragmentData.value
          break
        case 'Button1Text':
          returnData['buttonText'] = fragmentData.value
          break
        case 'Button1Url':
          returnData['buttonUrl'] = fragmentData.value
          break
        case 'Image':
          returnData['image'] = fragmentData.value
          break
        default:
          break
      }
    })
    return returnData
  })

  return fragmentDataMap[0]
}

const TeamPagePage = ({
  intl,
  page,
}) => {
  const {
    title: topBannerTitle,
    banner: topBannerImage,
    text: topBannerText,
    collection1title: group1Title,
    carouselTitle: group1Text,
    collection1Benefits: group1People,
    collection2Title: group2Title,
    collection2Benefits: group2People,
    collection3Title: group3Title,
    collection3Benefits: group3People,
    section3Data
  } = page
  console.log(page)
  const bottomBannerData = parseSection3Fragments(section3Data?.fragments)

  console.log(
    bottomBannerData
  )
  return (
    <>
      <Spacing removeSpaceTop removeSpaceBottom>
        <Banner
          title={topBannerTitle}
          bgImgUrl={topBannerImage[0]?.image?.localFile?.publicURL}
          text={topBannerText}
          overlay
        />
      </Spacing>
      <Spacing removeSpaceBottom>
        <TeamGroup
          title={group1Title}
          text={group1Text}
          people={group1People}
        />
      </Spacing>
      <Spacing removeSpaceBottom removeSpaceTop>
        <TeamGroup
          title={group2Title}
          people={group2People}
        />
      </Spacing>
      <Spacing removeSpaceBottom removeSpaceTop>
        <TeamGroup
          title={group3Title}
          people={group3People}
        />
      </Spacing>
      <Spacing>
        <InSalonRequestBanner smallButton items={[
          {
            title: bottomBannerData.title,
            btnText: bottomBannerData.buttonText,
            href: bottomBannerData.buttonUrl,
            url: bottomBannerData.buttonUrl,
            backgroundImage: bottomBannerData.image,
            fullWidth: true,
            splitColumns: true,
            overlayBackground: false,
            maintainAspectRatio: true,
          },
        ]} />
      </Spacing>
    </>
  )
}

export default injectIntl(TeamPagePage)
