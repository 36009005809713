import React from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import styled, { css } from 'styled-components'
import settings from "../../../kenra-storybook/global/settings"


const StContainer = styled.div`
    @media (max-width: ${settings.bp.small.view}) {
        max-width: 998px;
    }
    margin-left: 20%;
    margin-right: 20%;
    max-width: unset;
`

const StHero = styled.div`
    min-height: 420px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    overflow: hidden;

    @media (min-width: ${settings.bp.small.view}) {
        min-height: 650px;
    }

    .container {
        @media (min-width: ${settings.bp.small.view}) {
            padding: 5% 0;
        }
    }

    h1 {
        color: #fff;
        font-size: 48px;
        font-weight: 600;
        margin: 0;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        text-align: center;
        opacity: 1;
        @media (min-width: ${settings.bp.small.view}) {
            font-size: 64px;
        }

        ${props =>
        props.invert &&
        css`
                color: #000;
                background-color: #d3d3d380;
                max-width: 40%;
                margin: auto;
                padding: 12px;
            `};
    }
    h3 {
        color: #fff;
        font-size: 18px;
        font-weight: 400;
        margin: 0;
        letter-spacing: 0.1em;
        text-align: center;
    }
`

export const Banner = (props) => {
    const { title, bgImgUrl, invert, text, overlay } = props
    return (
        <StHero invert={invert} style={{ backgroundImage: `url(${bgImgUrl})`, boxShadow: "inset 0 0 0 1000px rgba(0,0,0,.7)" }}>
            <StContainer>
                <h1>{title}</h1>
                <h3>{documentToReactComponents(JSON.parse(text.raw || '{}'))}</h3>
            </StContainer>
        </StHero>
    )
}
