import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout';
import TeamPagePage from 'components/CustomLanding/TeamPage/TeamPagePage';
import SEO from 'components/seo';
import get from 'lodash.get';

export default function TeamPage(props) {
    let {
        data: { contentfulCustomLandingPage },
    } = props;

    console.log(contentfulCustomLandingPage);

    return (
        <Layout>
            <SEO
                description={get(
                    contentfulCustomLandingPage,
                    'seoListing.description.description'
                )}
                title={get(contentfulCustomLandingPage, 'seoListing.seoTitle')}
                slug={'meet-the-team'}
            />
            <TeamPagePage page={contentfulCustomLandingPage} />
        </Layout>
    );
}

export const query = graphql`
    query($locale: String) {
        contentfulCustomLandingPage(
            node_locale: { eq: $locale }
            slug: { eq: "meet-the-team" }
        ) {
            ...ContentfulCustomLandingPageFields
        }
    }
`;
